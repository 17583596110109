<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Slider Container', group: 'Sliders', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesSlidersSliderContainer.jpg',
      description: 'Slider of Images',
      tags: ['Media', 'Slider'],

      slots: [{ name: 'default' }],
      fields: {
        autoplay: {
          label: 'Autoplay',
          type: 'checkbox',
          reloadSlider: true,
        },
        controlStyle: {
          label: 'Control Style',
          type: 'select',
          options: { blocks: 'Blocks in the Bottom Right', arrows: 'Arrows on Either Side' },
          allowNull: true,
          reloadSlider: true,
        },
        showControls: {
          label: 'Show Controls',
          type: 'checkbox',
          reloadSlider: true,
        },
        showNavigation: {
          label: 'Show Navigation',
          type: 'checkbox',
          reloadSlider: true,
        },
      },
      templates: [{ label: 'Slider Container', fields: { controlStyle: { value: 'arrows' }, showControls: { value: true }, showNavigation: { value: true } } }],
    })

    // TODO handle event bus
    // deviseSettings.$bus.$on('devise-field-edited', function (payload) {
    //   if (payload.field && payload.field.reloadSlider) {
    //     self.$refs.slider.rebuild();
    //   }
    // })
  },
}
</script>

<template>
  <div class="relative slider-container z-10">
    <div style="height:0;">
&nbsp;
    </div>

    <Slider
      ref="slider"
      :devise="devise"
      :autoplay="fields.autoplay.checked"
      :control-style="fields.controlStyle.value"
      :show-controls="fields.showControls.checked"
      :show-navigation="fields.showNavigation.checked"
    >
      <slot />
    </Slider>
  </div>
</template>
